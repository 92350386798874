<template>
  <div>
    <table class="table table-sm table-bordered table-light mb-0">
      <thead>
        <tr>
          <th width="50%" class="text-center border-warning">ประเภท</th>
          <th width="50%" class="text-center border-warning">เงินรางวัล</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="prize in prizes">
          <td class="text-center border-warning">{{prize.text}}</td>
          <td class="text-right border-warning text-primary">{{prize.pay | amountFormat(2, '-')}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { marketTypes } from "@/configs/market.config"

export default {
  name: 'PrizeTable',
  props: ['market', 'rate'],
  computed: {
    marketOpenBets() {
      if(!this.market)
        return []

      const marketType = marketTypes.find(t=>t.code===this.market.marketType)
      if(!marketType)
        return []

      const set = marketType.sets.find(s=>s.set===this.market.marketSet)
      if(!set)
        return []

      return (set?.openBets || []).filter((o)=>{
        return this.market?.openBets?.[o.code]
      })
    },
    prizes() {
      return this.marketOpenBets.map((bet)=>{
        return {
          text: bet.text,
          pay: this.rate?.openBets?.[bet.code]?.isAvailable ? (this.rate?.openBets?.[bet.code]?.pay ?? 0) : 0
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  thead {
    tr {
      th {
        font-size: 85%;
      }
    }
  }
}
</style>
